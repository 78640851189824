var render = function render(){
  var _vm$$appConfig$brand$;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-quote"
  }, [_c('div', {
    staticClass: "page-quote-screen"
  }, [_vm.quote ? [_c('v-row', {
    attrs: {
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('span', {
    staticClass: "text-wrap display-1 ma-3"
  }, [_vm._v(" " + _vm._s(_vm.$t('Quote')) + " - " + _vm._s(_vm.quote.quoteNumber) + " ")]), _vm.quote.status === 'READY' ? _c('v-alert', {
    staticClass: "mb-0",
    attrs: {
      "type": "info",
      "text": "",
      "outlined": "",
      "icon": "fas fa-clock"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('QuoteReady')) + " ")]) : _vm.quote.status === 'ACCEPTED' || _vm.quote.status === 'CLOSED' ? _c('v-alert', {
    staticClass: "mb-0",
    attrs: {
      "type": "success",
      "text": "",
      "outlined": "",
      "icon": "fas fa-check-circle"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('QuoteAccepted')) + " ")]) : _vm.quote.status === 'DECLINED' ? _c('v-alert', {
    staticClass: "mb-0",
    attrs: {
      "type": "error",
      "text": "",
      "outlined": "",
      "icon": "fas fa-times-circle"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('QuoteDeclined')) + " ")]) : _vm._e(), _c('div', {
    staticClass: "ma-3"
  }, [_vm.quote ? _c('SupportEmail', {
    staticClass: "mr-2",
    attrs: {
      "hidden-pre-subject": `[QUOTE-${_vm.quote.quoteNumber}]`,
      "pre-subject": `${_vm.$t('Quote')} ${_vm.quote.quoteNumber} -`
    }
  }) : _vm._e(), _c('a', {
    ref: "quotePDFRef",
    staticClass: "d-none",
    attrs: {
      "download": `${_vm.$t('Quote')}_CMT_${_vm.quote.quoteNumber}`
    }
  }), _c('v-btn', {
    staticClass: "text-none white--text",
    attrs: {
      "color": "orange",
      "loading": _vm.loadingPdf,
      "ripple": false,
      "disabled": _vm.quote.status === 'LOCKED'
    },
    on: {
      "click": _vm.getQuotePDF
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-file-pdf ")]), _vm._v(" " + _vm._s(_vm.$t('exportQuote')) + " ")], 1)], 1)], 1), _c('v-divider', {
    staticClass: "my-5"
  }), _c('v-dialog', {
    attrs: {
      "primary-title": "",
      "max-width": "600",
      "persistent": ""
    },
    model: {
      value: _vm.dialogDeclineReason,
      callback: function ($$v) {
        _vm.dialogDeclineReason = $$v;
      },
      expression: "dialogDeclineReason"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "headline"
  }, [_vm._v(" " + _vm._s(_vm.$t('Reason')) + " ")]), _c('v-divider', {
    staticClass: "mb-1"
  }), _c('v-card', {
    staticClass: "pa-4",
    attrs: {
      "elevation": "0"
    }
  }, [_c('v-form', {
    ref: "declineReasonForm"
  }, [_c('v-textarea', {
    attrs: {
      "placeholder": _vm.$t('EnterReasonHere'),
      "required": "",
      "outlined": "",
      "auto-grow": true,
      "rules": _vm.messageRules
    },
    model: {
      value: _vm.declineReason,
      callback: function ($$v) {
        _vm.declineReason = $$v;
      },
      expression: "declineReason"
    }
  })], 1)], 1), _c('v-divider', {
    staticClass: "mb-1"
  }), _c('v-card-actions', [_c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "info"
    },
    on: {
      "click": function ($event) {
        _vm.dialogDeclineReason = false;
        _vm.declineReason = '';
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-arrow-circle-left ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t("back")) + " ")])], 1), _c('v-spacer'), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "loading": _vm.loadingButton,
      "disabled": _vm.loadingButton,
      "color": "error"
    },
    on: {
      "click": _vm.sendDeclineReason
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-times-circle ")]), _vm.quote.status === 'READY' ? _c('span', [_vm._v(" " + _vm._s(_vm.$t("Decline")) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.$t('CancelQuote')) + " ")])], 1)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "primary-title": "",
      "max-width": "600",
      "persistent": ""
    },
    model: {
      value: _vm.dialogAcceptQuote,
      callback: function ($$v) {
        _vm.dialogAcceptQuote = $$v;
      },
      expression: "dialogAcceptQuote"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "headline"
  }, [_vm._v(" " + _vm._s(_vm.$t('Confirmation')) + " ")]), _c('v-divider', {
    staticClass: "mb-1"
  }), _c('v-card', {
    staticClass: "pa-5",
    attrs: {
      "elevation": "0"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('OrderInformation')))])]), _c('v-divider', {
    staticClass: "mb-1"
  }), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "info"
    },
    on: {
      "click": function ($event) {
        _vm.dialogAcceptQuote = false;
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-times-circle ")]), _vm._v(" " + _vm._s(_vm.$t("CloseAndCancel")) + " ")], 1), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "loading": _vm.loadingButton,
      "disabled": _vm.loadingButton,
      "color": "success"
    },
    on: {
      "click": _vm.sendAccepted
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-paper-plane ")]), _vm._v(" " + _vm._s(_vm.$t("SignedAndAgreed")) + " ")], 1)], 1)], 1)], 1), _c('v-row', {
    staticClass: "flex-wrap-reverse justify-space-around"
  }, [_c('v-col', [_vm.hasPartToModify ? _c('v-alert', {
    attrs: {
      "type": "info",
      "text": "",
      "icon": "fas fa-exclamation-circle"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('AtLeastOnePartMustBeModified')) + " ")]) : _vm.quote.status === 'LOCKED' ? _c('v-alert', {
    staticClass: "info--text text-center",
    attrs: {
      "color": "orange",
      "colored-border": "",
      "border": "left",
      "prominent": "",
      "icon": "fas fa-info-circle"
    }
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.$t('YourQuoteIsBeingConfigured')) + " ")]), _c('p', [_vm._v(" " + _vm._s(_vm.$t('ThisUsuallyTakesBetween24And48Hours')) + " ")]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v(" " + _vm._s(_vm.$t('YouWillBeNotifiedWhenYourQuoteIsReady')) + " ")])]) : _vm._e(), _c('BeelseQuote', {
    attrs: {
      "quote": _vm.quote,
      "editable": _vm.quote.status === 'READY'
    },
    on: {
      "modifyDispatchCustomLineSelected": _vm.modifyDispatchCustomLineSelected,
      "modifyQuoteCustomLineSelected": _vm.modifyQuoteCustomLineSelected
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('QuoteValidation', {
    attrs: {
      "quote": _vm.quote,
      "private-supplier": (_vm$$appConfig$brand$ = _vm.$appConfig.brand.features.privateSuppliers.find(function (pS) {
        return _vm.quote.dispatch.some(function (d) {
          return pS.supplier._id === d.supplier;
        });
      })) === null || _vm$$appConfig$brand$ === void 0 ? void 0 : _vm$$appConfig$brand$.supplier
    },
    on: {
      "modifyPurchaseOrderNumber": _vm.updateQuotePurchaseOrder,
      "acceptQuote": function ($event) {
        _vm.dialogAcceptQuote = true;
      },
      "declineQuote": function ($event) {
        _vm.dialogDeclineReason = true;
      }
    }
  })], 1)], 1)] : _vm._e()], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }