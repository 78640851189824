<template lang="html" src="./quoteValidation.template.vue"></template>

<style lang="scss" src="./quoteValidation.scss"></style>

<script>

import PrivateSupplierInformation from '../privateSupplierInformation/PrivateSupplierInformation';
import BankTransferInstructions from '../bankTransferInstructions/BankTransferInstructions.vue';

const i18nData = require('./quoteValidation.i18n');

export default {
  name: 'QuoteValidation',
  components: {
    PrivateSupplierInformation,
    BankTransferInstructions
  },
  props: {
    quote: {
      type: Object,
      required: true,
    },
    privateSupplier: {
      type: Object,
      default: null
    }
  },
  watch: {
    'quote.purchaseOrderNumber': {
      handler: function(newVal, oldVal) {
        this.loadingModifyPurchaseOrder = false;
        if(newVal !== oldVal) {
          this.resetPurchaseOrderNumber();
        }
      },
    },
    $appConfig: {
      // handler: function(newVal, oldVal) {
      //   console.log('update');
      //   this.updatePaymentMethodRequirement();
      // },
      handler: 'updatePaymentMethodRequirement',
      deep: true
    }
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      loadingModifyPurchaseOrder: false,
      purchaseOrderNumber: null,
      paymentMethods: null,
      isPaymentMethodRequired: false,
      state: null,
      /**
       * Form validation rules
       */
      rules: {
        requiredPurchaseOrder: (value) =>
          (value && value.toString().trim() !== '') || this.$t('requiredPurchaseOrder'),
      },
      validPurchaseOrderNumberForm: false,
    };
  },
  created() {
    if(!this.$appConfig.paymentMethods) {
      this.getPaymentMethods();
    } else {
      this.paymentMethods = this.$appConfig.paymentMethods;
    }
    this.resetPurchaseOrderNumber();
    this.updatePaymentMethodRequirement();
  },
  methods: {
    getPaymentMethods() {
      this.$apiInstance.getBrandPaymentMethods(this.$route.params.brandUUID)
        .then(
          (paymentMethodsData) => {
            this.paymentMethods = paymentMethodsData;
            this.updatePaymentMethodRequirement();
          },
          (error) => {
            this.paymentMethods = [];
          }
        );
    },
    updatePaymentMethodRequirement() {
      this.isPaymentMethodRequired = this.quote.public && this.$appConfig.brand.features.defaultPaymentMethod === 'card'
        && !this.paymentMethods?.some(pM => {
          return  pM.card?.exp_year > new Date().getFullYear() || 
            (pM.card?.exp_year === new Date().getFullYear() && pM.card?.exp_month >= (new Date().getMonth() + 1));
        });
    },
    resetPurchaseOrderNumber() {
      this.purchaseOrderNumber = this.quote.purchaseOrderNumber;
    },
    acceptQuote() {
      if(!this.$appConfig.brand.features.forcePurchaseOrder || this.$refs.purchaseOrderNumberForm.validate()) {
        this.$emit('acceptQuote');
      }
    },
    declineQuote() {
      this.$emit('declineQuote');
    },
    clearAndSavePurchaseOrderNumber() {
      this.purchaseOrderNumber = '';
      this.modifyPurchaseOrderNumber();
    },
    modifyPurchaseOrderNumber() {
      this.$emit('modifyPurchaseOrderNumber', this.purchaseOrderNumber ?? '');
      this.loadingModifyPurchaseOrder = true;
    },
    customIsNullish(str) {
      return str === '' || str === null || str === undefined;
    },
  }
};
</script>
