<template lang="html" src="./pageQuote.template.vue"></template>

<style lang="scss" src="./pageQuote.scss"></style>

<script>
import { ApiErrorParser, BeelseQuote } from '@cloudmanufacturingtechnologies/portal-components';
import QuoteValidation from '../../components/quoteValidation/QuoteValidation';

import SupportEmail from '../../components/supportEmail/SupportEmail';

const i18nData = require('./pageQuote.i18n');

export default {
  name: 'PageQuote',
  components: { BeelseQuote, QuoteValidation, SupportEmail },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      loadingButton: false,
      loadingPdf: false,
      hasValidPaymentMethods: null,
      quote: null,
      pdfData: null,
      disabled: true,
      declineReason: null,
      dialogDeclineReason: false,
      dialogAcceptQuote: false,
      messageRules: [(value) => !!value || this.$t('ReasonRequired')],
    };
  },
  computed: {
    hasPartToModify() {
      if(this.quote.status === 'ACCEPTED' || this.quote.status === 'CLOSED' || this.quote.status === 'DECLINED') {
        return false;
      }

      for(const item of this.quote.items) {
        if(item.part?.status === 'NEW' || item.part?.homologation?.status === 'REJECTED') {
          return true;
        }
      }
      return false;
    }
  },
  created() {
    this.checkValidPaymentMethods();
    this.getQuote();
  },
  updated() {
    if(!this.hasValidPaymentMethods) {
      this.checkValidPaymentMethods();
    }
  },
  methods: {
    checkValidPaymentMethods() {
      if(this.$appConfig?.brand?.features?.defaultPaymentMethod && this.$appConfig?.paymentMethods) {
        this.hasValidPaymentMethods = false;
        if(['card', 'sepa'].includes(this.$appConfig.brand.features.defaultPaymentMethod)) {
          const currentYear = new Date().getFullYear();
          const currentMonth = new Date().getMonth() + 1;
          this.$appConfig.paymentMethods.forEach(paymentMethod => {
            if(
              paymentMethod?.card?.exp_year > currentYear || 
              (paymentMethod?.card?.exp_year === currentYear && paymentMethod?.card?.exp_month > currentMonth) || 
              paymentMethod.type === 'sepa_debit'
            ) {
              this.hasValidPaymentMethods = true;
            }
          });
        }else{
          //Payment methods is one of transfer or auto
          this.hasValidPaymentMethods = true;
        }
      }
    },
    getQuote() {
      this.disabled = true;
      this.pdfData = null;
      this.quote = null;
      /**
       * GET BRAND QUOTE
       */
      this.$apiInstance
        .getBrandQuote(
          this.$appConfig.currentBrand,
          this.$route.params.quoteUUID
        )
        .then(
          (data) => {
            this.quote = data;
          },
          (error) => {
            /**
             * ERROR GET BRAND QUOTE
             * Component BeelseNotifications used
             */
            this.$notification.notify('ERROR', this.$t(ApiErrorParser.parse(error)));
          }
        );
    },
    getQuotePDF() {
      if(!this.pdfData) {
        this.loadingPdf = true;
        /**
         * GET QUOTE PDF
         */
        this.$apiInstance
          .getBrandQuotePDF(this.quote.brand, this.quote._id, {lang: this.$userLocale})
          .then(
            (pdf) => {
              this.pdfData = pdf.data;
              this.disabled = false;
              this.$refs.quotePDFRef.href = `data:application/pdf;base64,${this.pdfData}`;
              setTimeout(() => {
                this.$refs.quotePDFRef.click();
              }, 250);
            },
            (error) => {
              /**
               * ERROR GET QUOTE PDF
               *
               * Component BeelseNotifications used
               */
              this.$notification.notify('ERROR', this.$t(ApiErrorParser.parse(error)));
              this.disabled = true;
            }
          )
          .finally(() => {
            this.loadingPdf = false;
          });
      } else {
        this.$refs.quotePDFRef.click();
      }
    },
    unescapeString(s) {
      return unescape(s);
    },
    updateQuotePurchaseOrder(purchaseOrderNumber) {
      this.loadingButton = true;
      const modifyBrandQuotePurchaseOrderBody = new this.$BcmModel.ModifyBrandQuotePurchaseOrderBody();
      modifyBrandQuotePurchaseOrderBody.purchaseOrder = purchaseOrderNumber;
      this.$apiInstance
        .modifyBrandQuotePurchaseOrder(
          this.$appConfig.currentBrand,
          this.$route.params.quoteUUID,
          modifyBrandQuotePurchaseOrderBody
        )
        .then(
          () => {
            this.$notification.notify('SUCCESS', this.$t('PurchaseOrderUpdated'));
            this.getQuote();
          },
          (error) => {
            this.$notification.notify('ERROR', ApiErrorParser.parse(error));
          }
        )
        .finally(() => {
          this.loadingButton = false;
        });
    },
    modifyDispatchCustomLineSelected(supplierUUID, customLineUUID, selected) {
      const modifyBrandQuoteDispatchCustomLineSelectedBody = new this.$BcmModel.ModifyBrandQuoteDispatchCustomLineSelectedBody(
        selected
      );

      this.$apiInstance.modifyBrandQuoteDispatchCustomLineSelected(
        this.$route.params.brandUUID,
        this.$route.params.quoteUUID,
        supplierUUID,
        customLineUUID,
        modifyBrandQuoteDispatchCustomLineSelectedBody
      ).then(data => {
        this.$notification.notify('SUCCESS', this.$t('QuoteHasBeenUpdated'));
      }, (error) => {
        this.$notification.notify('ERROR', ApiErrorParser.parse(error));
      }).finally(() => {
        this.getQuote();
      });
    },
    modifyQuoteCustomLineSelected(customLineUUID, selected) {
      const modifyBrandQuoteCustomLineSelectedBody = new this.$BcmModel.ModifyBrandQuoteCustomLineSelectedBody(
        selected
      );

      this.$apiInstance.modifyBrandQuoteCustomLineSelected(
        this.$route.params.brandUUID,
        this.$route.params.quoteUUID,
        customLineUUID,
        modifyBrandQuoteCustomLineSelectedBody
      ).then(data => {
        this.$notification.notify('SUCCESS', this.$t('QuoteHasBeenUpdated'));
      }, (error) => {
        this.$notification.notify('ERROR', ApiErrorParser.parse(error));
      }).finally(() => {
        this.getQuote();
      });
    },
    /**
     * ACCEPT BRAND QUOTE
     */
    sendAccepted() {
      this.loadingButton = true;
      this.$apiInstance
        .acceptBrandQuote(
          this.$appConfig.currentBrand,
          this.$route.params.quoteUUID
        )
        .then(
          (data) => {
            this.quote = data;
            this.dialogAcceptQuote = false;
            this.$router.push({name: 'Order', params: {brandUUID: `${this.$appConfig.currentBrand}`, orderUUID: `${this.quote.order}`}});
          },
          (error) => {
            /**
             * ERROR ACCEPT BRAND QUOTE
             * Component BeelseNotifications used
             */
            this.$notification.notify('ERROR', this.$t(ApiErrorParser.parse(error)));
          }
        )
        .finally(() => {
          this.loadingButton = false;
        });
    },
    /**
     * DECLINE BRAND QUOTE
     */
    sendDeclineReason() {
      if (
        this.$refs.declineReasonForm.validate() &&
        this.declineReason &&
        this.declineReason.trim() !== ''
      ) {
        this.loadingButton = true;

        const declineBrandQuoteBody = new this.$BcmModel.DeclineBrandQuoteBody(
          this.declineReason
        );
        this.$apiInstance
          .declineBrandQuote(
            this.$appConfig.currentBrand,
            this.$route.params.quoteUUID,
            declineBrandQuoteBody
          )
          .then(
            () => {
              this.dialogDeclineReason = false;
              this.getQuote();
            },
            (error) => {
              /**
               * ERRPR DECLINE BRAND QUOTE
               * Component BeelseNotifications used
               */
              this.$notification.notify('ERROR', this.$t(ApiErrorParser.parse(error)));
            }
          )
          .finally(() => {
            this.loadingButton = false;
          });
      }
    },
  },
};
</script>
