var render = function render(){
  var _vm$quote$closedBy, _vm$quote$closedBy2, _vm$quote$closedBy3, _vm$quote$closedBy4, _vm$$appConfig, _vm$$appConfig$fundin;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "component-quoteValidation"
  }, [_c('v-card', [_c('v-card-text', [_c('div', {
    staticClass: "text-h6 black--text mb-4 d-flex align-center"
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "color": "info",
      "small": ""
    }
  }, [_vm._v(" fas fa-file-signature ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('Quote')) + " - " + _vm._s(_vm.quote.quoteNumber) + " ")])], 1), _c('v-divider'), _c('v-simple-table', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function () {
        return [_c('tbody', [_c('tr', [_c('td', {
          staticClass: "col-item-price-ht font-weight-medium",
          attrs: {
            "width": "70%"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('NetTotalVATExcluded')) + " ")]), _c('td', {
          staticClass: "col-item-total-ht text-right"
        }, [_vm.quote.selling ? _c('span', [_vm._v(" " + _vm._s(_vm.$n(_vm.quote.selling.totalAmountWithoutVAT, "currency", "fr")) + " ")]) : _c('span', [_vm._v(" --,-- € ")])])]), _c('tr', [_c('td', {
          staticClass: "pl-8 text-caption custom-dense-td"
        }, [_vm._v(" " + _vm._s(_vm.$t('IncludingDeliveryOf')) + " ")]), _c('td', {
          staticClass: "custom-dense-td text-right"
        }, [_vm.quote.selling ? _c('span', [_vm._v(" " + _vm._s(_vm.$n(_vm.quote.selling.deliveryPrice, "currency", "fr")) + " ")]) : _c('span', [_vm._v(" --,-- € ")])])]), _vm.quote.selling && _vm.quote.selling.totalDiscount !== '0' ? _c('tr', [_c('td', {
          staticClass: "pl-8 text-caption custom-dense-td"
        }, [_vm._v(" " + _vm._s(_vm.$t('IncludingDiscountOf')) + " ")]), _c('td', {
          staticClass: "custom-dense-td text-right"
        }, [_vm._v(" " + _vm._s(_vm.$n(_vm.quote.selling.totalDiscount, "currency", "fr")) + " ")])]) : _vm._e(), _c('tr', [_c('td', {
          staticClass: "font-weight-medium"
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('TotalVAT')) + " ")])]), _c('td', {
          staticClass: "text-right"
        }, [_vm.quote.selling ? _c('span', [_vm._v(" " + _vm._s(_vm.$n(_vm.quote.selling.vatTotal, "currency", "fr")) + " ")]) : _c('span', [_vm._v(" --,-- € ")])])]), _c('tr', [_c('td', {
          staticClass: "font-weight-medium"
        }, [_vm._v(" " + _vm._s(_vm.$t('TotalAmountVATIncluded')) + " ")]), _c('td', {
          staticClass: "text-right text-h6"
        }, [_vm.quote.selling ? _c('span', [_vm._v(" " + _vm._s(_vm.$n(_vm.quote.selling.totalAmount, "currency", "fr")) + " ")]) : _c('span', [_vm._v(" --,-- € ")])])])])];
      },
      proxy: true
    }])
  }), _c('v-divider', {
    staticClass: "mb-4"
  }), _c('v-form', {
    ref: "purchaseOrderNumberForm",
    attrs: {
      "lazy-validation": ""
    },
    model: {
      value: _vm.validPurchaseOrderNumberForm,
      callback: function ($$v) {
        _vm.validPurchaseOrderNumberForm = $$v;
      },
      expression: "validPurchaseOrderNumberForm"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": _vm.$appConfig.brand.features.forcePurchaseOrder ? _vm.$t('PurchaseOrderLabelMandatory') : _vm.$t('PurchaseOrderLabel'),
      "clearable": !_vm.customIsNullish(_vm.purchaseOrderNumber),
      "disabled": _vm.quote.status !== 'READY' && _vm.quote.status !== 'LOCKED',
      "required": _vm.$appConfig.brand.features.forcePurchaseOrder,
      "rules": _vm.$appConfig.brand.features.forcePurchaseOrder ? [_vm.rules.requiredPurchaseOrder] : [],
      "maxlength": 29,
      "counter": ""
    },
    on: {
      "click:clear": _vm.clearAndSavePurchaseOrderNumber
    },
    model: {
      value: _vm.purchaseOrderNumber,
      callback: function ($$v) {
        _vm.purchaseOrderNumber = $$v;
      },
      expression: "purchaseOrderNumber"
    }
  }), _vm.quote.status === 'READY' || _vm.quote.status === 'LOCKED' ? _c('v-btn', {
    staticClass: "text-none mb-4",
    attrs: {
      "color": "info",
      "width": "100%",
      "disabled": !_vm.validPurchaseOrderNumberForm || _vm.purchaseOrderNumber === _vm.quote.purchaseOrderNumber || _vm.customIsNullish(_vm.purchaseOrderNumber) && _vm.customIsNullish(_vm.quote.purchaseOrderNumber),
      "loading": _vm.loadingModifyPurchaseOrder
    },
    on: {
      "click": _vm.modifyPurchaseOrderNumber
    }
  }, [_c('v-icon', {
    staticClass: "mr-3",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-save ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('SavePurchaseOrderNumber')) + " ")])], 1) : _vm._e()], 1), _c('v-divider', {
    staticClass: "mb-4"
  }), _vm.quote.status === 'READY' || _vm.quote.status === 'ACCEPTED' ? _c('div', [_c('v-row', [_c('v-col', [_c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "error",
      "disabled": _vm.quote.status === 'ACCEPTED' || !(_vm.purchaseOrderNumber === _vm.quote.purchaseOrderNumber || _vm.customIsNullish(_vm.purchaseOrderNumber) && _vm.customIsNullish(_vm.quote.purchaseOrderNumber))
    },
    on: {
      "click": _vm.declineQuote
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-times-circle ")]), _vm._v(" " + _vm._s(_vm.$t('Decline')) + " ")], 1)], 1), _c('v-col', [_c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "success",
      "disabled": _vm.isPaymentMethodRequired || !(_vm.purchaseOrderNumber === _vm.quote.purchaseOrderNumber || _vm.customIsNullish(_vm.purchaseOrderNumber) && _vm.customIsNullish(_vm.quote.purchaseOrderNumber))
    },
    on: {
      "click": _vm.acceptQuote
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-check-circle ")]), _vm._v(" " + _vm._s(_vm.$t('Accept')) + " ")], 1)], 1)], 1), _vm.isPaymentMethodRequired ? _c('v-row', [_c('v-col', [_c('v-alert', {
    staticClass: "text-center",
    attrs: {
      "color": "info",
      "text": "",
      "outlined": ""
    }
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.$t('APaymentMethodIsRequired')) + " ")]), _c('div', [_c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "info",
      "width": "100%",
      "to": {
        name: 'AdministrationPaymentMethods',
        params: {
          brandUUID: `${_vm.quote.brand}`
        }
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-users-cog ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('ManagePaymentMethods')) + " ")])], 1)], 1)])], 1)], 1) : _vm._e(), _vm.quote.expirationDate ? _c('v-row', [_c('v-col', [_c('v-alert', {
    attrs: {
      "color": "info",
      "text": ""
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "color": "info",
      "small": ""
    }
  }, [_vm._v(" fas fa-info-circle ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('QuoteValidUntil')) + " " + _vm._s(_vm.$d(new Date(_vm.quote.expirationDate), 'numeric', _vm.$userLocale)) + " ")])], 1)], 1)], 1) : _vm._e()], 1) : _vm.quote.status === 'LOCKED' ? _c('v-row', [_c('v-col', [_c('v-alert', {
    staticClass: "text-center mb-0",
    attrs: {
      "color": "info",
      "text": ""
    }
  }, [_c('div', {
    staticClass: "mb-2"
  }, [_c('v-icon', {
    attrs: {
      "color": "info"
    }
  }, [_vm._v(" fas fa-lock ")])], 1), _c('div', [_vm._v(" " + _vm._s(_vm.$t('VerificationsPerformedForThisQuote1')) + " ")]), _c('div', [_vm._v(" " + _vm._s(_vm.$t('VerificationsPerformedForThisQuote2')) + " ")])])], 1)], 1) : _vm.quote.status === 'CLOSED' ? _c('v-row', [_c('v-col', [_c('p', {
    staticClass: "success--text text-center"
  }, [_c('v-icon', {
    staticClass: "mr-2 mb-1",
    attrs: {
      "color": "success",
      "small": ""
    }
  }, [_vm._v(" far fa-check-circle ")]), _vm._v(" " + _vm._s(_vm.$t('AcceptedBy')) + " " + _vm._s((_vm$quote$closedBy = _vm.quote.closedBy) === null || _vm$quote$closedBy === void 0 ? void 0 : _vm$quote$closedBy.firstname) + " " + _vm._s((_vm$quote$closedBy2 = _vm.quote.closedBy) === null || _vm$quote$closedBy2 === void 0 ? void 0 : _vm$quote$closedBy2.lastname) + " " + _vm._s(_vm.$t('On').toLowerCase()) + " " + _vm._s(_vm.$d(new Date(_vm.quote.closedDate), 'numeric')) + " ")], 1), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "width": "100%",
      "color": "info",
      "to": {
        name: 'Order',
        params: {
          brandUUID: `${_vm.quote.brand}`,
          orderUUID: `${_vm.quote.order}`
        }
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-3",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-box-open ")]), _vm._v(" " + _vm._s(_vm.$t('ViewOrder')) + " ")], 1)], 1)], 1) : _vm.quote.status === 'DECLINED' ? _c('v-row', [_c('v-col', [_c('p', {
    staticClass: "error--text text-center"
  }, [_c('v-icon', {
    staticClass: "mr-2 mb-1",
    attrs: {
      "color": "error",
      "small": ""
    }
  }, [_vm._v(" far fa-times-circle ")]), _vm._v(" " + _vm._s(_vm.$t('RefusedBy')) + " " + _vm._s((_vm$quote$closedBy3 = _vm.quote.closedBy) === null || _vm$quote$closedBy3 === void 0 ? void 0 : _vm$quote$closedBy3.firstname) + " " + _vm._s((_vm$quote$closedBy4 = _vm.quote.closedBy) === null || _vm$quote$closedBy4 === void 0 ? void 0 : _vm$quote$closedBy4.lastname) + " " + _vm._s(_vm.$t('On').toLowerCase()) + " " + _vm._s(_vm.$d(new Date(_vm.quote.closedDate), 'numeric')) + " ")], 1), _c('v-textarea', {
    attrs: {
      "readonly": "",
      "label": _vm.$t('DeclineReason'),
      "outlined": "",
      "auto-grow": "",
      "rows": "1"
    },
    model: {
      value: _vm.quote.declineReason,
      callback: function ($$v) {
        _vm.$set(_vm.quote, "declineReason", $$v);
      },
      expression: "quote.declineReason"
    }
  })], 1)], 1) : _vm._e(), _vm.quote.status === 'LOCKED' ? _c('v-row', {
    attrs: {
      "justify": "end"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "error",
      "small": ""
    },
    on: {
      "click": _vm.declineQuote
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-times-circle ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('CancelQuote')) + " ")])], 1)], 1)], 1) : _vm._e()], 1)], 1), _vm.quote.billing && (_vm.quote.public || _vm.quote.dispatch[0].onlinePayment) ? _c('v-card', {
    staticClass: "mt-2"
  }, [_c('v-card-title', [_vm._v(_vm._s(_vm.$t('paymentAvailability')))]), _c('v-card-text', [_c('p', [_c('span', {
    staticClass: "mr-2 font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$t('byCard')))]), _c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v(" fas fa-credit-card ")]), _c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v(" fab fa-cc-visa ")]), _c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v(" fab fa-cc-mastercard ")]), _c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v(" fab fa-cc-amex ")])], 1), ((_vm$$appConfig = _vm.$appConfig) === null || _vm$$appConfig === void 0 ? void 0 : (_vm$$appConfig$fundin = _vm$$appConfig.fundingInstructions) === null || _vm$$appConfig$fundin === void 0 ? void 0 : _vm$$appConfig$fundin.length) > 0 ? _c('p', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('byWireTransfer')) + " ")]) : _vm._e(), _c('p', [_c('BankTransferInstructions', {
    attrs: {
      "cols": "12"
    }
  })], 1)])], 1) : _vm._e(), _vm.privateSupplier && !_vm.quote.public ? _c('PrivateSupplierInformation', {
    attrs: {
      "supplier": _vm.privateSupplier,
      "display-payment-information": !_vm.quote.public && _vm.quote.billing && !_vm.quote.dispatch[0].onlinePayment && !!_vm.privateSupplier.paymentConfiguration
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }